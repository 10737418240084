import { ref } from 'vue';
import { debounce, startCase, upperFirst } from 'lodash';

export const convertToTitleCase = (str) => {
  if (!str) return '';

  var words = str.split('_');
  words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1).toLowerCase();

  for (var i = 1; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase();
  }

  var result = words.join(' ');

  return result;
}

export const stringToArray = (idsString) => {
  return idsString.split(',').map(Number);
}

export const groupBy = (array, key) => {
  return array.reduce((result, currentValue) => {
    (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
    return result;
  }, {});
}

export const pluck = (array, key) => {
  return array.map(item => item[key]);
}

export const replaceDashWithUnderscore = (str) => {
  if(!str) return "";
  return str.replace(/-/g, "_");
}

export const replaceDashWithSpace = (str, textTransform = null) => {
  if(!str) return "";
  let formattedTxt = str.replace(/-/g, " ");

  if(textTransform === 'capitalized'){
    formattedTxt = startCase(formattedTxt);
  }

  return formattedTxt;
}

export const joinArrayOfString = (texts) => {

  if (!texts.length) return '';

  let joinedString = '';

  if (texts.length > 1) {
      joinedString = texts.slice(0, -1).join(', ') + ' and ' + texts.slice(-1);
      return joinedString;
  } 

  joinedString = texts[0];

  return joinedString;
}

export const getCurrentFormattedDateTime = () => { // 2023-12-19 11:39:43 PM
    const currentDate = new Date();

    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');

    let hours = currentDate.getHours();
    const minutes = String(currentDate.getMinutes()).padStart(2, '0');
    const seconds = String(currentDate.getSeconds()).padStart(2, '0');
    const meridiem = hours >= 12 ? 'PM' : 'AM';
  
    hours = hours % 12 || 12;

    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds} ${meridiem}`;
  
    return formattedDateTime;
}

export const toCapitalizeFirst = (value) => {
  if (!value) return '';
  return upperFirst(value.toLowerCase());
}

export const formatDate = (date) => {
  var formattedDate = date.toISOString().split('T')[0];
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var amOrPm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12;
  var formattedTime = hours + ':' + (minutes < 10 ? '0' : '') + minutes + ' ' + amOrPm;

  return formatToDdMmYy(formattedDate) + ' ' + formattedTime
}

export const chunkArray = (array, chunkSize) => {
  let resultArray = [];
  
  for (let i = 0; i < array.length; i += chunkSize) {
    let chunk = array.slice(i, i + chunkSize);
    resultArray.push(chunk);
  }

  return resultArray;
}

const formatToDdMmYy = (date) => {
  var dat = date.split('-');
  return `${dat[2]}-${dat[1]}-${dat[0]}`;
}

export const formatDateToDdMmYy = (date) => {  // Output: "18/09/2023"
  if(!date) return '';

  var parsedDate = new Date(date);
  var formattedDate = ("0" + parsedDate.getDate()).slice(-2) + "/" + ("0" + (parsedDate.getMonth() + 1)).slice(-2) + "/" + parsedDate.getFullYear();
  return formattedDate;
}


export const checkNullAndFormatValue = (text) => {
  if (!text) return '';
  if (text === 'null') return '';
  return text;
}

export const removeAbnormalDate = (date) => {
  if (!date) return '';
  if (date == '0000-00-00') {
    return '';
  }
  return date;
}

export const formatCurrency = (value) => {
  return new Intl.NumberFormat('en-IN', {
    minimumFractionDigits: 0
  }).format(value);
}

export const commaFormat = (value) => {
  if (!value) {
    return 0.00
  }

  return new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'BDT' }).format(value).replace('BDT', '').trim()
}

export const generateUUID = () => {  // Output: "f47ac10b-58cc-4372-a567-0e02b2c3d479"
  const cryptoObj = window.crypto || window.msCrypto;
  const array = new Uint8Array(16);
  cryptoObj.getRandomValues(array);
  const uuid = array.reduce((acc, value, index) => {
    if (index === 4 || index === 6 || index === 8 || index === 10) {
      return acc + '-';
    }
    const str = value.toString(16).padStart(2, '0');
    return acc + str;
  }, '');
  return uuid;
}

export const formatNumberToTwoDecimals = (value) => {
  return Math.floor(value * 100) / 100;
} 

export const getCurrentDateTime = (date = null) => {
  const currentData = date ? new Date(date) : Date.now();
  const offset =  new Date().getTimezoneOffset();
  const localDate = new Date(currentData - offset * 60000);
  return localDate.toISOString().slice(0, 16);
};

export const json_string = (value) => {
  return JSON.stringify(value);
} 

export const json_parse = (value) => {
  return JSON.parse(value);
} 

export const json_string_parse = (value) => {
  return json_parse(json_string(value));
} 

  // date of barth convert to age
export const dateOfBirthConvertToAge = (birthday) => {
    const birthDateObj = new Date(birthday);
    const currentDate = new Date();
    const timeDifference = currentDate - birthDateObj;
    const year = Math.floor(timeDifference / (365.25 * 24 * 60 * 60 * 1000));
    const month = Math.floor(
        (timeDifference % (365.25 * 24 * 60 * 60 * 1000)) / (30.44 * 24 * 60 * 60 * 1000)
    );
    const day = Math.floor(
        (timeDifference % (30.44 * 24 * 60 * 60 * 1000)) / (24 * 60 * 60 * 1000)
    );
    return {year, month, day}
}

// format item title with details for print
export const getProductParticulars = (hasItemDetail, item) => {
  if(hasItemDetail) {
    return item.product.name +
      (!item.description ? "" : `\n${item.description}`) +
      (!item.batch_number ? "" : `\n${item.batch_number}`)
  }

  return item.head.name
}


export const useDebouncedSearch = (searchFunction, delay = 300) => {
  // Create a ref for the search query
  const searchQuery = ref('');

  // Create a debounced version of the search function
  const debouncedSearch = debounce(searchFunction, delay);

  // Watch for changes in the searchQuery and call the debounced function
  const handleSearch = () => {
    debouncedSearch();
  };


  return {
    handleSearch,
  };
}

export const addBaseUrl = (url) => {
  const formattedUrl = url.replace(/^\/|\/$/g, '');
  return `${process.env.VUE_APP_BASE_URL}/${formattedUrl}`;
}

export const addFrontBaseUrl = (url) => {
  const formattedUrl = url.replace(/^\/|\/$/g, '');
  return `${window.location.origin}/${formattedUrl}`;
}
